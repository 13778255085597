import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import i18n from '@/libs/i18n/index'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useOfficeList() {
  // Use toast
  const toast = useToast()

  const refOfficeListTable = ref(null)

  // Table Handlers
  const tableColumns = computed(() => {
    return [
      { key: 'name', label: i18n.t('officesTable.name'), sortable: true },
      { key: 'city', label: i18n.t('officesTable.city'), sortable: true },
      { key: 'phone', label: i18n.t('officesTable.phone'), sortable: true },
      { key: 'email', label: i18n.t('officesTable.email'), sortable: true },
      { key: 'isActive', label: i18n.t('officesTable.active'), sortable: true },
      { key: 'actions', label: '' },
    ]
  })
  const perPage = ref(10)
  const totalOffice = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const isActive = ref(1)

  const dataMeta = computed(() => {
    const localItemsCount = refOfficeListTable.value
      ? refOfficeListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalOffice.value,
    }
  })

  const refetchData = () => {
    refOfficeListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const onSubmit = (data) => {
    store
      .dispatch('app-office/setOffice', data)
      .then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('toasts.save.success'),
            icon: 'CheckIcon',
            variant: 'success',
          },
        })

        router.push({ name: 'offices-list' })
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('toasts.save.error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchOffice = (ctx, callback) => {
    store
      .dispatch('app-office/fetchOffices', {
        headers: {
          'X-Sort': `offices.${sortBy.value}`,
          'X-Sort-Order': isSortDirDesc.value ? 'desc' : 'asc',
          'X-Paginate': true,
        },
        query: {
          search: searchQuery.value,
          page: currentPage.value,
          perPage: perPage.value,
        },
      })
      .then((res) => {
        const { total } = res
        totalOffice.value = total
        callback(res.data)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('toasts.fetch.error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const listOffices = () =>
    new Promise((resolve, reject) => {
      store
        .dispatch('app-office/fetchOffices', {
          headers: {
            'X-Sort': `offices.${sortBy.value}`,
            'X-Sort-Order': isSortDirDesc.value ? 'desc' : 'asc',
            'X-Paginate': false,
          },
          query: {
            search: searchQuery.value,
            page: currentPage.value,
            perPage: perPage.value,
            isActive: isActive.value
          },
        })
        .then((res) => {
          const { total } = res
          totalOffice.value = total
          const parsedOffices = []
          res.data.forEach((element) => {
            parsedOffices.push({
              key: element.id,
              label: element.name,
            })
          })
          resolve(parsedOffices)
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: i18n.t('toasts.fetch.error'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          reject(e)
        })
    })

  const deleteOffice = (id) => {
    store
      .dispatch('app-office/deleteOffice', id)
      .then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('toasts.delete.success'),
            icon: 'CheckIcon',
            variant: 'success',
          },
        })

        refetchData()
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('toasts.delete.error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const resolveStatusVariant = (status) => {
    if (status === false) return 'warning'
    if (status === true) return 'success'
    return 'primary'
  }

  const resolveStatusText = (status) => {
    if (status === false) return 'inactive'
    if (status === true) return 'active'
    return '-'
  }

  return {
    fetchOffice,
    listOffices,
    tableColumns,
    perPage,
    currentPage,
    totalOffice,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refOfficeListTable,

    refetchData,
    onSubmit,
    deleteOffice,

    resolveStatusText,
    resolveStatusVariant,
  }
}
